<template>
  <div>
    <keep-alive>
      <component :is="current" />
    </keep-alive>
  </div>
</template>
<script lang="ts" setup>
import { pageview } from 'vue-gtag'
const { activeSlide: idx, nextSlide, prevSlide } = useNavigation()

useHead({
  title: 'Submit Sighting | Enigma Labs'
})

onMounted(() => {
  pageview({
    page_path: '/submit',
    page_title: 'Submit Sighting | Enigma Labs'
  })

  const env = useRuntimeConfig().public.environment
  const segment = useSegment()
  if (env === 'production' || env === 'staging') {
    // confirm before leaving page
    window.onbeforeunload = function () {
      segment.track('submission_window_unload', {}, { traits: { visitorId: useFingerprint().fingerprint.value } })

      return ''
    }

    anonId()
  }

  keyListeners()
})

watch(idx, () => {
  pageview({
    page_path: '/submit',
    page_title: `Submit Sighting | Enigma Labs | Slide ${idx.value}`
  })
})

useDevData()

async function anonId() {
  const { getFingerprint } = useFingerprint()

  await getFingerprint()
  tagUser()
}

function keyListeners() {
  onKeyStroke('ArrowDown', (e) => {
    if (!slideIsValid(idx.value)) {
      log.debug('Slide invalid, ArrowDown ignored')
      return
    }

    const { target } = e as any
    if (target.nodeName.toLowerCase() === 'body') {
      nextSlide()
    }
  })

  onKeyStroke('ArrowUp', (e) => {
    const { target } = e as any
    if (target.nodeName.toLowerCase() === 'body') {
      prevSlide()
    }
  })

  onKeyStroke('Enter', (e) => {
    if (!slideIsValid(idx.value)) {
      log.debug('Slide invalid, Return key press ignored')
      return
    }

    const { target } = e as any
    if (target.nodeName.toLowerCase() === 'textarea') {
      return
    }

    e.stopPropagation()
    nextSlide()
  })
}

const current = computed(() => {
  switch (idx.value) {
    case 0:
      return resolveComponent('SlideOnboarding')
    case 1:
      return resolveComponent('SlideLocation')
    case 2:
      return resolveComponent('SlideDateTime')
    case 3:
      return resolveComponent('SlideStory')
    case 4:
      return resolveComponent('SlideBehavior')
    case 5:
      return resolveComponent('SlideFeatures')
    case 6:
      return resolveComponent('SlideWitnessCount')
    case 7:
      return resolveComponent('SlideSensor')
    case 8:
      return resolveComponent('SlideMedia')
    case 9:
      return resolveComponent('SlideSocial')
    case 10:
      return resolveComponent('SlideName')
    case 11:
      return resolveComponent('SlideSummary')
    default:
      log.error('Invalid slide index', idx.value)
  }
})

</script>
